.card {
    display:flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 15px;
    width: 750px;
}

@media only screen and (max-width: 768px) {
    .card {
        width: 350px;
    }
}