h3 {
    font-family: 'sen'
}

.error {
    font-family: 'sen';
    font-weight: 600;
    color: red;
}

.text {
    color: black;
    font-family: 'sen';
    font-weight: 700;
}

.text-label {
    color: black;
    font-family: 'sen';
    font-weight: 700;
}

.body {
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px
}

.loading-body {
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.form-wrapper {
    min-width: 300px;
    width: max-content;
    height: min-content;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.form-body {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.upload-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.btn{
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 15px;
  background: #cd1a4f;
  display: inline-block;
  color: white;
  font-family: 'sen';
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
}
    
.btn:hover{
    cursor: pointer;
}

.file-input-wrapper {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.file-input {
    margin-bottom: 10px;
}

label.label input[type="file"] {
    position: absolute;
    top: -1000px;
}

.label {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 15px;
  background: #cd1a4f;
  display: inline-block;
  color: white;
  font-family: 'sen';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 110px;
}
.label:hover {
  cursor: pointer;
}
.label:active {
  background: #9fa1a0;
}
.label:invalid+span {
  color: white;
  font-weight: 700;
  font-family: 'sen';
}
.label:valid+span {
  color: #ffffff;
  font-weight: 700;
  font-family: 'sen';
}

.select-wrapper {
  position: relative;
  float: left;
  min-width: 200px;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
display: none;
}

.select-wrapper:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/
  
  top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  /*left line */
  
  position: absolute;
  pointer-events: none;
}

.select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  
  display: block;
  width: 100%;
  max-width: 320px;
  height: 50px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}
  
.form-title {
  color: black;
  font-family: 'sen';
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  text-justify: center;
}

.wip-text {
  color: black;
  font-family: 'sen';
  font-size: 20px;
  font-weight: 500;
}

.admin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

table {
  width: 100%;
}

tr {
  font-family: 'sen';
  font-weight: 700;
}

td {
  padding: 10px;
}

@media only screen and (max-width: 768px){
  .form-wrapper{
    width: 350px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }

  .form-body{
    width: 300px;
  }

  .input-field{
    width: 300px;
  }
}