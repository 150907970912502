.navbar {
    width: 100%;
    height:80px;
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    justify-content: flex-start;
    position:fixed;
    top: 0px;
    overflow: hidden;
    z-index: 20;
}

.navbar-login {
    width: 100%;
    height:80px;
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    position:fixed;
    top: 0px;
    overflow: hidden;
    z-index: 20;
}

.nav-items {
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.nav-items-login {
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-left: -100px;
}

.vgs-icon {
    width: 40px;
    height: 40px;
    margin-left: 50px;
}

.nav-item-anchor {
    color: black;
    text-decoration: none;
}

.nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    background: linear-gradient(to bottom, white 50%, black 50%) top;
    background-size: 200% 200%;
    transition: all 0.8s ease;
}

.nav-item:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    border-bottom: 12px solid black;
    transition: 100ms;
}

.nav-item-text {
    font-family: 'sen';
    font-weight: 400;
}

.nav-item-active {  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height:80px;
    background: linear-gradient(to top, black 50%, white 50%) bottom;
    background-size: 200% 200%;
    transition: all 0.8s ease;
}

.nav-item-active-text {
    font-family: 'sen';
    font-weight: 400;
    color: white;
}

.login-button {
    margin-right: 60px;
    width: 80px;
    height: 30px;
    border: 3px solid #cd1a4f;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-family: 'sen';
    color:#cd1a4f;
}

.login-button:hover {
    margin-right: 60px;
    width: 80px;
    height: 30px;
    border: 3px solid #cd1a4f;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-family: 'sen';
    color:#cd1a4f;
    cursor: pointer;
    background-color: #cd1a4f;
}

.login-button:hover > p {
    color:white;
}
@media only screen and (max-width: 768px){
    .navbar {
        width: 100%;
        height:50px;
        display: flex;
        flex-direction: row;
        background-color: white;
        align-items: center;
        justify-content: space-between;
        position:fixed;
        top: 0px;
        overflow: hidden;
        z-index: 20;
    }
    
    .navbar-login {
        width: 100%;
        height:50px;
        display: flex;
        flex-direction: row;
        background-color: white;
        align-items: center;
        justify-content: space-between;
        position:fixed;
        top: 0px;
        overflow: hidden;
        z-index: 20;
    }
    
    .nav-items {
        width: 50px;
        font-size: 10px;
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .nav-items-login {
        width: 50px;
        font-size: 10px;
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-left: 0px;
    }
    
    .vgs-icon {
        width: 30px;
        height: 30px;
        margin-left: 20px;
    }
    
    .nav-item-anchor {
        color: black;
        text-decoration: none;
    }
    
    .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 60px;
        background: linear-gradient(to bottom, white 50%, black 50%) top;
        background-size: 200% 200%;
        transition: all 0.8s ease;
    }
    
    .nav-item:hover {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        border-bottom: 12px solid black;
        transition: 100ms;
    }
    
    .nav-item-text {
        font-family: 'sen';
        font-weight: 400;
    }
    
    .nav-item-active {  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height:60px;
        background: linear-gradient(to top, black 50%, white 50%) bottom;
        background-size: 200% 200%;
        transition: all 0.8s ease;
    }
    
    .nav-item-active-text {
        font-family: 'sen';
        font-weight: 400;
        color: white;
    }
    
    .login-button {
        margin-right: 10px;
        width: 70px;
        height: 25px;
        border: 3px solid #cd1a4f;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-family: 'sen';
        color:#cd1a4f;
        font-size: 14px;
    }
    
    .login-button:hover {
        margin-right: 30px;
        width: 70px;
        height: 25px;
        border: 3px solid #cd1a4f;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-family: 'sen';
        color:#cd1a4f;
        cursor: pointer;
        background-color: #cd1a4f;
    }
    
    .login-button:hover > p {
        color:white;
    }    
}