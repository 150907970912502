body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-bar-pages {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.logo {
  height: 60%;
}

.body-logo {
  width: 200px;
  height: 200px;
}

html {
  scroll-behavior: smooth;
}

/* Scrollbar styles for WebKit browsers (Safari, Chrome, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
  
::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}
  
::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar thumb */
}

/* Scrollbar styles for Firefox */
/* Note: Firefox does not support customizing the scrollbar appearance */

/* Scrollbar styles for IE and Edge */
::-ms-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
  
::-ms-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}
  
::-ms-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar thumb */
}