.body-wrapper {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}

.err-box {
    margin-top:120px;
    width: 500px;
    height:50px;
    display:flex;
    flex-direction: row;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px solid black;
    align-items: center;
    justify-content: space-around;
}

.close-icon:hover {
    cursor:pointer;
}

.err-msg {
    font-family:'sen';
    font-weight:900;
    color: red;
}

.white-card {
    margin: 125px 0px;
    width:850px;
    height:400px;
    background-color: white;
    border-radius: 20px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 40px;
}

.white-card-error {
    margin: 60px 100px;
    width:850px;
    height:400px;
    background-color: white;
    border-radius: 20px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 40px;   
}

.left-card-cp {
    width:360px;
    height: 375px;
    background-color: #33B4C1;
    border-radius:20px;
}

.left-card-heading {
    font-family: 'sen';
    font-weight: 800;
    color:white;
    font-size:45px;
    text-align: left;
    margin-left:30px;
}

.left-card-text {
    text-align: center;
    font-family: 'sen';
    color: white;
    font-weight:700;
}

.top-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:5px;
}

.text-logo {
    width:200px;
    height:80px;
}

.right-card-wrapper {
    text-align: left;
    width: 400px;
    height:320px;
}

.right-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:10px;
}

.right-card-text-cp {
    text-align: start;
    color:#33B4C1;
    font-family:'sen';
    font-weight:800;
}

.input-label {
    font-family: 'sen';
    font-weight:700;
}

input {
    border-radius:5px;
    padding: 12px 16px;
    height:40px;
    width:340px;
    box-sizing: border-box;
}

.btn-cp{
font-family: 'sen';
font-weight: 0;
font-size: 20px;
color: #fff;
background-color: #33B4C1;
padding: 10px 30px;
border: none;
box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
border-radius: 8px;
transition : 1000ms;
transform: translateY(0);
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
text-transform: uppercase;
}

.btn-cp:hover{
    cursor: pointer;
}

.contacts-wrapper {
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    justify-content: center;
    gap:50px;
    background-color: transparent;
    margin-top: -30px;
}

.contact-logo {
    width:30px;
    height:30px;
    opacity: 0.5;
}

@media only screen and (max-width: 768px){
    .body-wrapper {
        width:100%;
        height:900px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content: center;
    }
    
    .err-box {
        margin-top: 25px;
        margin-bottom: -50px;
        width: 350px;
        height:50px;
        display:flex;
        flex-direction: row;
        background-color: whitesmoke;
        border-radius: 5px;
        border: 1px solid black;
        align-items: center;
        justify-content: space-around;
    }
    
    .close-icon:hover {
        cursor:pointer;
    }
    
    .err-msg {
        font-family:'sen';
        font-weight:900;
        color: red;
        font-size: 25px ;
    }
    
    .white-card {
        margin: 125px 0px;
        width:350px;
        height:670px;
        background-color: white;
        border-radius: 20px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 40px;
        margin-top: 75px;
    }
    
    .white-card-error {
        margin: 60px 100px;
        width:350px;
        height:650px;
        background-color: white;
        border-radius: 20px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 40px;   
    }
    
    .left-card-cp {
        width:360px;
        height: 375px;
        background-color: #33B4C1;
        border-radius:20px;
        scale: 0.85;
        margin-top: -5px;
    }
    
    .left-card-heading {
        font-family: 'sen';
        font-weight: 800;
        color:white;
        font-size:45px;
        text-align: left;
        margin-left:30px;
    }
    
    .left-card-text {
        text-align: center;
        font-family: 'sen';
        color: white;
        font-weight:700;
    }
    
    .top-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:5px;
    }
    
    .text-logo {
        width:200px;
        height:80px;
    }
    
    .right-card-wrapper {
        text-align: left;
        width: 400px;
        height:320px;
    }
    
    .right-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap:10px;
        scale: 0.75;
        margin-top: -95px;
    }
    
    .right-card-text-cp {
        text-align: start;
        color:#33B4C1;
        font-family:'sen';
        font-weight:800;
    }
    
    .input-label {
        font-family: 'sen';
        font-weight:700;
        font-size: 20px;
    }
    
    input {
        border-radius:5px;
        padding: 12px 16px;
        height:40px;
        width:340px;
        box-sizing: border-box;
        font-size: 20px;
    }
    
    .btn-cp{
    font-family: 'sen';
    font-weight: 0;
    font-size: 20px;
    color: #fff;
    background-color: #33B4C1;
    padding: 10px 30px;
    border: none;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition : 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    }
    
    .btn-cp:hover{
        cursor: pointer;
    }
    
    .contacts-wrapper {
        display: flex;
        flex-direction: row;
        width:100%;
        align-items: center;
        justify-content: center;
        gap:50px;
        background-color: transparent;
        margin-top: -50px;
    }
    
    .contact-logo {
        width:30px;
        height:30px;
        opacity: 0.5;
        margin-bottom: 15px;
    }
}