.body {
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap:10px;
}

.btn-cms{
    width: 150px;
    font-family: Roboto, sans-serif;
    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #33B4C1;
    padding: 10px 30px;
    border: none;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition : 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    }
    
.btn-cms:hover{
    cursor: pointer;
}

.session-title {
    color: white;
    font-family: 'sen';
    font-size: 28px;
    font-weight: 800;
    margin-left: 30px;
}